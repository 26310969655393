import React from "react";
import { getUserImage } from "../../utils/helpers";
import UserAvatarSkeleton from "../skeleton/UserAvatarSkelton";

export default function Image({ src, alt, className = "" }) {
  return (
    <>
      {src ? (
        <img
          src={src || getUserImage()}
          alt={alt || "User Image"}
          className={`${className} img-fluid rounded-circle`}
          onError={(e) => {
            e.target.src = getUserImage();
          }}
        />
      ) : (
        <UserAvatarSkeleton className={className} />
      )}
    </>
  );
}
