import React from "react";
import { Helmet } from "react-helmet";
import { defaultDescription, mainKeywords } from "../utils/constants";

const { REACT_APP_WEBSITE_NAME } = process.env;

export default function MyHelmet({
  title = "FeelingHub",
  description = defaultDescription,
  link = "",
  keywords = "",
  noIndex = false,
}) {
  const metaTitle = title ? "FeelingHub | ".concat(title) : "";
  const metaLink = REACT_APP_WEBSITE_NAME + link;
  const metaKeywords = keywords ? mainKeywords.concat(`, ${keywords}`) : mainKeywords;
  const metaRobots = noIndex ? "noindex, nofollow" : "index, follow";
  const favIcon = `${REACT_APP_WEBSITE_NAME}/favicon.ico`;

  return (
    <Helmet>
      <title>{metaTitle}</title>
      <meta name="description" content={description} />
      <link rel="canonical" href={metaLink} />
      <meta name="keywords" content={metaKeywords} />
      <meta name="robots" content={metaRobots} />
      <link rel="icon" href={favIcon} />

      {/* OG TAgs */}
      <meta property="og:url" title={metaLink} />
      <meta property="og:title" title={metaTitle} />
      <meta property="og:description" title={description} />
      <meta property="og:type" title="website" />
      <meta property="og:image" title={favIcon} />

      {/* Twitter Tags */}
      {/* <meta property="twitter:site" title={""} /> */}
    </Helmet>
  );
}
