import React, { useEffect } from "react";
import { Col, Form, Row } from "react-bootstrap";
import { useSelector } from "react-redux";
import { useFormik } from "formik";
import { conatctUsSchema } from "../../utils/validationSchemas";
import { successMsg } from "../../utils/toastMessage";
import { contactUsReason } from "../../utils/constants";
import { addQuery } from "../../redux/queries/thunk";
import { handleApiRequest } from "../../services/handleApiRequest";
import { ErrorField } from "../../Component/common/errorField";
import PhoneInput from "react-phone-input-2";
import MySelect from "../../Component/common/mySelect";
import AskToShare from "../../Component/askToShare";
import { SubFooter } from "../../Component/Footer/Footer";
import HeartLine from "../../Component/common/heartLine";
import { Helmet } from "react-helmet";
import {
  FacebookHandle,
  InstagramHandle,
  TelegramHandle,
  YoutubeHandle,
} from "../../Component/common/socialHandles";

const defaultValues = {
  name: "",
  mobile: "",
  email: "",
  reason: "",
  comment: "",
};

const ContactUs = () => {
  return (
    <>
      <Helmet>
        <title>FeelingHub | Contact Us</title>
      </Helmet>
      <section className="contactusWrapper position-relative">
        <ContactUsForm />
        <HeartLine />
        <AskToShare />
        <SubFooter />
      </section>
    </>
  );
};

export default ContactUs;

export const ContactUsForm = () => {
  const { userProfile } = useSelector((state) => state.auth);

  const handleSubmitQuery = async () => {
    const response = await handleApiRequest(addQuery, values);
    resetForm();

    if (response.status) {
      successMsg("Thanks for your response");
    }
  };

  let {
    values,
    errors,
    handleChange,
    setFieldValue,
    setValues,
    handleSubmit,
    isSubmitting,
    resetForm,
    touched,
  } = useFormik({
    validateOnChange: false,
    validateOnBlur: false,
    initialValues: defaultValues,
    validationSchema: conatctUsSchema,
    onSubmit: handleSubmitQuery,
  });

  useEffect(() => {
    if (userProfile._id) {
      const { name, mobile, email } = userProfile;
      setValues({ ...defaultValues, name, mobile, email });
    }
  }, [userProfile]);

  return (
    <Row className="align-items-center m-0 px-lg-5 px-2 my-5">
      <Col md={5}>
        <button className="outlineBtn rounded-pill mb-3">Don't be shy</button>
        <h1 className="fw-normal">
          Get in <span className="text-primary">Touch</span>
        </h1>
        <p>
          So you are interested in hearing more or express yourself in more different ways, thinking
          of working with me, or just think it would be good to have a chat? Just tell us a bit
          about yourself and we’ll be in touch.
        </p>
        <div className="d-none d-md-block">
          <div className="mt-2 d-flex align-items-center">
            <FacebookHandle />
            <InstagramHandle />
            <TelegramHandle />
            <YoutubeHandle />
          </div>
        </div>
      </Col>
      <Col md={1} className="d-none d-md-block" />
      <Col md={6} lg={5}>
        <h2 className="fw-normal">
          Let's <span className="text-primary">Talk</span>
        </h2>
        <p className="">Let’s bring your idea to life together.</p>
        <Form onSubmit={handleSubmit} style={{ maxWidth: 500 }}>
          <div className="mt-3">
            <input
              type="text"
              className="form-control"
              placeholder="Enter Full Name*"
              name="name"
              value={values.name}
              onChange={handleChange}
            />
            <ErrorField touched={touched} error={errors.name} />
          </div>

          <Row>
            <Col sm={6} className="mt-3 pe-sm-0">
              <div>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Email Address*"
                  name="email"
                  value={values.email}
                  onChange={handleChange}
                />
                <ErrorField touched={touched} error={errors.email} />
              </div>
            </Col>
            <Col sm={6} className="mt-3">
              <div>
                <PhoneInput
                  inputClass="w-100 border"
                  buttonClass="border"
                  inputStyle={{ height: 38 }}
                  country="in"
                  placeholder={"Phone Number*"}
                  value={values.mobile}
                  onChange={(value, data, e, formattedValue) => {
                    setFieldValue("mobile", formattedValue);
                  }}
                />
                <ErrorField touched={touched} error={errors.mobile} />
              </div>
            </Col>
          </Row>

          <div className="mt-3">
            <MySelect
              placeholder="Select reason*"
              options={contactUsReason}
              value={values.reason}
              onChange={(selected) => setFieldValue("reason", selected)}
            />

            <ErrorField touched={touched} error={errors.reason} />
          </div>

          <div className="my-3 my-sm-3">
            <textarea
              className="form-control"
              placeholder="Your message*"
              name="comment"
              value={values.comment}
              onChange={handleChange}
            />

            <ErrorField touched={touched} error={errors.comment} />
          </div>

          {isSubmitting ? (
            <button className="secondaryBtn muted rounded-pill px-3 py-1 text-white" disabled>
              Submitting
              <i className="growLoader spinner-grow ms-2" role="status" />
            </button>
          ) : (
            <button
              type="submit"
              className="primaryBtn rounded-pill px-3 py-1"
              disabled={isSubmitting}
            >
              Send
            </button>
          )}
        </Form>
      </Col>
    </Row>
  );
};
