import React from "react";
import Skeleton from "react-loading-skeleton";
import { skeletonBaseColor, skeletonHighlightColor } from "../../utils/constants";

export default function UserAvatarSkeleton({ className = "" }) {
  return (
    <Skeleton
      circle
      className={`${className} img-fluid`}
      baseColor={skeletonBaseColor}
      highlightColor={skeletonHighlightColor}
    />
  );
}
