import React, { createContext, useEffect, useState } from "react";
import { io } from "socket.io-client";
import { errorMsg } from "../utils/toastMessage";
import { useSelector } from "react-redux";

const url = process.env.REACT_APP_BACKEND_BASE_URL;
export const Context = createContext();

export default function ContextProvider({ children }) {
  const { loggedinUser } = useSelector((state) => state.auth);

  const [socket, setSocket] = useState("");

  const handleSocketConnection = async () => {
    try {
      const mySocket = io(url, {
        // const mySocket = io("https://feelinghub-backend-p07d.onrender.com", {
        // const mySocket = io("http://localhost:4000", {
        // autoConnect: false,
        transports: ["websocket", "polling", "flashsocket"],
        auth: {
          token: loggedinUser.token,
          userId: loggedinUser.userId,
        },
      });

      // mySocket.auth = { token: "test" };
      // socket.connect();

      if (mySocket) {
        setSocket(mySocket);

        mySocket.emit("client", { userId: loggedinUser.userId }, (response) => {
          console.log("client socket connected", response);
        });

        mySocket.on("connect_error", (err) => {
          console.error("Socket connection error:", err);
          errorMsg(`Connection Error: ${err.message}`);
          setSocket(null);
          mySocket.disconnect();
        });

        mySocket?.on("error", (err) => {
          console.error("General socket Error:", err);
          setSocket(null);
        });
      }
    } catch (error) {
      console.log("socket connection error", error);
    }
  };

  useEffect(() => {
    if (loggedinUser?.userId) {
      handleSocketConnection();
    }
  }, [loggedinUser]);

  return <Context.Provider value={{ socket }}>{children}</Context.Provider>;
}
