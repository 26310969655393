import store from "../redux/store";
import { manageLoginPop } from "../redux/common/slice";
import { myAccountRoute, profileRoute } from "../routes/routesPath";
import { femaleAvatarHolder, maleAvatarHolder } from "./constants";
import moment from "moment";
import { format_date, format_time } from "./formatersAndParsers";

export const getUserImage = (user) => {
  if (!user) return maleAvatarHolder;

  if (user?.avatar?.url) {
    return user.avatar.url;
  }
  if (user?.gender === "female") {
    return femaleAvatarHolder;
  }
  return maleAvatarHolder;
};

export const getMyAccountPagePath = (path) => {
  if (!path) return `${myAccountRoute}${profileRoute}`;
  return `${myAccountRoute}${path}`;
};

export const handleLoginPop = (action) => {
  const dispatch = store.dispatch;
  dispatch(manageLoginPop(action));
};

export const isArray = (array) => {
  if (Array.isArray(array)) return array;
  return [];
};

export const getStorySlug = (title) => {
  if (!title) return "";

  let slug = title
    .replace(/[^a-zA-Z0-9\s]/g, "")
    .split(" ")
    ?.join("_");

  return slug;
};

export const asycnSetState = (state, setState) => {
  return Promise.resolve(setState(state));
};

export const getLastMessageDateTime = (date) => {
  if (!date || !moment(date).isValid()) return "";

  const newDate = new Date();
  const today = newDate.getDate();

  const msgDate = new Date(date);
  const msgDay = msgDate.getDate();

  if (msgDay === today) return format_time(date);
  else if (msgDay === today - 1) return "Yesterday";
  else return moment(date).format("DD MMM");
};
