import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import emptyPrivateFolder from "../../assets/images/noPrivateStory.png";
import TransparentSelect from "../../Component/common/transparentSelect";
import { defaultPage, limitOptions, sortingOptions } from "../../utils/constants";
import { isArray } from "../../utils/formatersAndParsers";
import { handleApiRequest } from "../../services/handleApiRequest";
import { handleTransformValues } from "../../utils/formatersAndParsers";
import { getMainStoriesList } from "../../redux/stories/thunk";
import AskToShare from "../../Component/askToShare";
import MySelect from "../../Component/common/mySelect";
import MyPagination from "../../Component/common/pagination";
import StoryCard from "../../Component/storyCard/myStoryCard";
import ReactionsListsPop from "../../Component/Modals/reactionsListPop";
import LoadIndicator from "../../Component/Loader";
import CardSkeleton from "../../Component/skeleton/cardSkeleton";
import { addStoryRoute } from "../../routes/routesPath";

export default function PrivateDirectory() {
  const navigate = useNavigate();
  const { categories } = useSelector((state) => state.common);
  const { privateStoriesList = {}, loadingStoriesList = false } = useSelector(
    (state) => state.stories || {}
  );

  const [userAction, setUserAction] = useState(null);
  const [paginationDetails, setPaginationDetails] = useState({
    ...defaultPage,
    orderBy: sortingOptions[0],
  });
  const [filters, setFilters] = useState({});

  const handleFiltersChange = (name, value) => {
    setFilters((prev) => ({ ...prev, [name]: value }));
  };

  const handleLimit = (selected) => {
    setPaginationDetails((prev) => ({ ...prev, limit: selected }));
  };

  const handleStories = async () => {
    const selectedFilters = await handleTransformValues(filters);

    const request = {
      ...paginationDetails,
      orderBy: paginationDetails.orderBy?.orderBy,
      ...selectedFilters,
      isPrivate: true,
      listType: "user",
      listFor: "private",
    };
    await handleApiRequest(getMainStoriesList, request);
  };

  const feelingFilterOption = (option) => {
    return (
      <div className="d-flex align-items-center">
        <span
          className="optionIcon me-2"
          dangerouslySetInnerHTML={{ __html: option.iconRegular }}
        />
        <span className="">{option.name}</span>
      </div>
    );
  };

  useEffect(() => {
    handleStories();
  }, [paginationDetails, filters]);

  return (
    <>
      <div className="w-100 overflow-auto">
        {privateStoriesList.totalCount > 0 && (
          <div
            className={`${
              loadingStoriesList ? "" : "hide-loader"
            } d-flex align-items-center justify-content-center`}
          >
            <LoadIndicator />
          </div>
        )}
        {privateStoriesList.totalCount === 0 ? (
          <div className="text-center my-4">
            <h3 className="m-0">FeelingHub is just for you...</h3>
            <img src={emptyPrivateFolder} className="w-100" style={{ maxWidth: 400 }} />
            <h4 className="text-uppercase m-0">Write your memories and experience</h4>
            <p className="">Anything you save here will be visible to you only</p>
            <button
              className="primaryBtn rounded px-3 py-2"
              onClick={() => navigate(addStoryRoute)}
            >
              Write for yourself
            </button>
          </div>
        ) : (
          <>
            <div className="storyFilters d-block d-sm-flex align-items-center justify-content-between p-3 px-lg-5">
              <TransparentSelect
                placeholder="Relevance"
                className="sortingSelect"
                options={sortingOptions}
                value={paginationDetails.orderBy}
                onChange={(selected) =>
                  setPaginationDetails((prev) => ({
                    ...prev,
                    orderBy: selected,
                    order: selected.order,
                  }))
                }
              />
              <TransparentSelect
                placeholder="Filter by Feeling"
                className="storyFilterContainer"
                options={isArray(categories)}
                getOptionLabel={(option) => option.name}
                getOptionValue={(option) => option._id}
                formatOptionLabel={feelingFilterOption}
                value={filters.category}
                onChange={(selected) => handleFiltersChange("category", selected)}
              />
            </div>

            <div className={`px-2 px-lg-3 pt-3`}>
              {isArray(privateStoriesList.records).length > 0
                ? privateStoriesList.records?.map((story, i) => (
                    <StoryCard
                      key={i}
                      story={story}
                      handleReactionsList={setUserAction}
                      storyType="private"
                    />
                  ))
                : Array.from({ length: 3 }).map((_) => <CardSkeleton />)}
              <div className="d-flex justify-content-between py-3">
                <div>
                  <MySelect
                    classNamePrefix={"mySelect"}
                    options={limitOptions}
                    isSearchable={false}
                    value={paginationDetails.limit}
                    onChange={handleLimit}
                  />
                </div>
                <MyPagination
                  paginationDetails={paginationDetails}
                  setPaginationDetails={setPaginationDetails}
                  totalCount={privateStoriesList.totalCount}
                  darkPagination={true}
                />
              </div>
            </div>
          </>
        )}

        <div className="curvatureContainer">
          <div>
            <div id="curved-corner-topright" />
          </div>
          <div>
            <div id="curved-corner-topleft" />
          </div>
        </div>

        <AskToShare />
      </div>
      {userAction?.action === "showReactionsList" && (
        <ReactionsListsPop userAction={userAction} setUserAction={setUserAction} />
      )}
    </>
  );
}
