import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { BiHomeHeart } from "react-icons/bi";
import { PiBookOpenUserBold } from "react-icons/pi";
import { FaRegUserCircle } from "react-icons/fa";
import useAuth from "../../hooks/useAuth";
import * as Path from "../../routes/routesPath";
import { FaRegCommentDots } from "react-icons/fa";
import MyAccount from "../sidebars/myAccount";

export default function BottomTab() {
  const { isAuthenticated } = useAuth();
  const navigate = useNavigate();

  const [userAction, setUserAction] = useState({});

  return (
    <>
      <div className="mobileBottomTabWrapper d-block d-sm-none">
        <div className="mobileBottomTab position-ralative">
          <button
            className="mobileAddStoryBtn textBtn rounded-circle d-flex justify-content-center align-items-center"
            onClick={() => navigate(isAuthenticated ? Path.addStoryRoute : Path.signinRoute)}
          >
            +
          </button>
        </div>
        <div className="bottomTabActions h-100 d-flex align-items-center justify-content-between">
          <span className="text-white text-center mx-3" onClick={() => navigate("/")}>
            <BiHomeHeart />
            <p className="text-medium text-white m-0">Home</p>
          </span>
          <span className="text-white text-center mx-3" onClick={() => navigate(Path.storiesRoute)}>
            <PiBookOpenUserBold />
            <p className="text-medium text-white m-0">Stories</p>
          </span>
          <span className="text-white text-center mx-3" onClick={() => navigate(Path.chat)}>
            <FaRegCommentDots />
            <p className="text-medium text-white m-0">Chat</p>
          </span>
          <span
            className="text-white text-center mx-3"
            onClick={() => {
              setUserAction({ action: "showMyAccountOptions" });
            }}
          >
            <FaRegUserCircle />
            <p className="text-medium text-white m-0">Profile</p>
          </span>
        </div>
      </div>
      <MyAccount userAction={userAction} setUserAction={setUserAction} />
    </>
  );
}
