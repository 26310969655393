import React from "react";
import { Button, Modal } from "react-bootstrap";
import { PiWarningCircleBold } from "react-icons/pi";

export default function BlockChat({ userAction, setUserAction, handleBlock }) {
  const handleClose = () => {
    setUserAction({});
  };

  return (
    <Modal
      show={!!userAction}
      onHide={handleClose}
      backdrop="static"
      keyboard={false}
      centered
      size="md"
      className="reportStoryPop"
    >
      <Modal.Body>
        <div className="text-center py-3">
          <div className="icn mb-3">
            <PiWarningCircleBold className="text-warning" style={{ width: 100, height: 100 }} />
          </div>

          <h4 className="pop-head m-0 pb-2">Are you Sure?</h4>
          {userAction?.action === "blockUser" && (
            <p className="mb-4">
              You will not be able to send or <br /> receive message form this user
            </p>
          )}
          <div className="btn-wrap my-2 d-flex align-items-center justify-content-center mt-3">
            <Button
              onClick={handleClose}
              className="btn me-2 d-flex align-items-center justify-content-center bg-secondary border-0"
            >
              Cancel
            </Button>
            <Button
              onClick={() => handleBlock(userAction?.action === "blockUser" ? "block" : "unblock")}
              className="d-flex align-items-center justify-content-center bg-danger border-0"
              style={{ minWidth: 100 }}
            >
              {userAction?.action === "blockUser" ? "Block" : "Unblock"}
            </Button>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}
