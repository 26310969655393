import React from "react";
import { Outlet } from "react-router-dom";
import Header from "../Component/Header/Header";
import Footer from "../Component/Footer/Footer";
import BottomTab from "../Component/mobileBottomTab/bottomTab";

export default function MainTheme() {
  return (
    <div className="mainWrapper position-relative shadow-sm">
      <Header />
      <main className="mainBody">
        <Outlet />
      </main>
      <Footer />
      <BottomTab />
    </div>
  );
}
