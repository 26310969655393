import React from "react";
import { Col, Row } from "react-bootstrap";
import helpPeopleLikeYou from "../../assets/images/helpPeople.png";
import ShareBtn from "../common/shareBtn";

export default function AskToShare() {
  return (
    <Row className="align-items-center py-5 px-3 px-lg-5 mx-0">
      <Col md={8} className="">
        <div className="content">
          <h2 className="">Help People like you</h2>
          <p className="bannerDesc my-4">
            In an age where technology dominates our daily lives. We are dedicated to provide
            support and resources for those struggling with mental health issues, relationship
            problems, or other challenges by bringing people together. By sharing these platforms
            with more individuals, we can expand the reach of these communities and ensure that no
            one feels isolated or alone in their struggles.
            <br />
            <ul>
              <li>Share the community with the people in need.</li>
              <li>Donate us to reach more people like you.</li>
            </ul>
          </p>
        </div>
        <div className="d-flex align-items-center gap-10 mb-4">
          <ShareBtn />
        </div>
      </Col>
      <Col md={4} className="text-center">
        <img
          src={helpPeopleLikeYou}
          className="img-fluid rounded-circle"
          style={{ maxHeight: 300 }}
        />
      </Col>
    </Row>
  );
}
