import React from "react";
import Skeleton from "react-loading-skeleton";
import { skeletonBaseColor, skeletonHighlightColor } from "../../utils/constants";

export default function UserHolderSkeleton() {
  return (
    <div className="shadow-sm rounded mb-2 p-1 p-sm-2 d-flex align-items-center justify-content-between">
      <div className="d-flex align-items-center">
        <Skeleton
          circle
          className="img-medium"
          baseColor={skeletonBaseColor}
          highlightColor={skeletonHighlightColor}
        />

        <div className="ms-2">
          <Skeleton
            width={120}
            baseColor={skeletonBaseColor}
            highlightColor={skeletonHighlightColor}
          />
          <Skeleton
            width={180}
            baseColor={skeletonBaseColor}
            highlightColor={skeletonHighlightColor}
          />
        </div>
      </div>
      <span className="reactionIconFromList me-1 me-sm-3">
        <Skeleton
          className="img-small rounded"
          baseColor={skeletonBaseColor}
          highlightColor={skeletonHighlightColor}
        />
      </span>
    </div>
  );
}
