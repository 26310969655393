import React from "react";
import { getStorySlug, getUserImage } from "../../utils/helpers";
import { useNavigate } from "react-router-dom";
import { storyDetailsRoute } from "../../routes/routesPath";

export default function StoryCard({ story = {} }) {
  const navigate = useNavigate();

  return (
    <div
      className="homeStoryCard pointer rounded mx-2 px-2 py-4 p-lg-4 text-center"
      onClick={() => navigate(`${storyDetailsRoute}/${getStorySlug(story.title)}/${story._id}`)}
    >
      <div className="d-flex justify-content-center">
        <img
          src={story.anonymousSharing ? getUserImage() : getUserImage(story.user)}
          alt={story.anonymousSharing ? "userImage" : story.user?.name}
          onError={(e) => {
            e.target.src = getUserImage();
          }}
          className="img-small img-fluid object-fit-cover rounded-circle"
        />
      </div>
      <h5 className="my-3 text-truncate">{story.title || ""} </h5>
      <div
        className="storyDesc truncate-4 m-0"
        dangerouslySetInnerHTML={{ __html: story.description }}
      />
    </div>
  );
}
