import React from "react";
import Skeleton from "react-loading-skeleton";
import { skeletonBaseColor, skeletonHighlightColor } from "../../utils/constants";

export default function UserDetailsSkeleton({ className = "" }) {
  return (
    <div className={`py-4 px-3 px-lg-5 w-100 ${className}`}>
      <div className="d-flex align-items-center">
        <Skeleton
          circle
          className="img-medium"
          baseColor={skeletonBaseColor}
          highlightColor={skeletonHighlightColor}
        />
        <div className="ms-2">
          <Skeleton
            width={150}
            baseColor={skeletonBaseColor}
            highlightColor={skeletonHighlightColor}
          />
          <Skeleton
            width={150}
            baseColor={skeletonBaseColor}
            highlightColor={skeletonHighlightColor}
          />
        </div>
      </div>
      <div className="my-2">
        <Skeleton
          count={2}
          className=""
          baseColor={skeletonBaseColor}
          highlightColor={skeletonHighlightColor}
        />
      </div>
    </div>
  );
}
