import React, { useEffect, useState } from "react";
import { reactionsIcons } from "../../utils/constants";
import { BiLike } from "react-icons/bi";
import { handleApiRequest } from "../../services/handleApiRequest";
import { manageStoryReaction } from "../../redux/stories/thunk";
import useAuth from "../../hooks/useAuth";
import { handleLoginPop } from "../../utils/helpers";

export default function AddReaction({
  savedReaction,
  reactionsCount = 0,
  storyId = "",
  setStoryDetails,
  position = "",
}) {
  const { isAuthenticated } = useAuth();
  const [myReaction, setMyReaction] = useState("");

  const handleReaction = async (e, reaction) => {
    e.stopPropagation();
    if (!isAuthenticated) return handleLoginPop(true);

    setMyReaction(reaction);
    const element = document.getElementById(`reactedIcon-${storyId}`);
    if (element) {
      element.classList.remove("popup");
      requestAnimationFrame(() => {
        element.classList.add("popup");
      });
    }

    setStoryDetails((prev) => ({
      ...prev,
      reactionsCount: myReaction ? prev.reactionsCount : (prev.reactionsCount || 0) + 1,
    }));

    const request = { storyId, reaction };
    const response = await handleApiRequest(manageStoryReaction, request);
    if (!response.status) {
      setStoryDetails((prev) => ({
        ...prev,
        reactionsCount: myReaction ? prev.reactionsCount : (prev.reactionsCount || 0) - 1,
      }));
      setMyReaction("");
    }
  };

  useEffect(() => {
    if (!myReaction) {
      setMyReaction(savedReaction);
    }
  }, [storyId]);

  return (
    <div className="reactionContainer">
      <p>
        <span id={`reactedIcon-${storyId}`} className="reactedIcon popup">
          {myReaction ? reactionsIcons[myReaction] : <BiLike />}
        </span>
        <span className="d-none d-sm-inline-block ms-1">Reacts </span>
        <span>({reactionsCount || 0})</span>
      </p>

      <div
        className="reactionOptionContainer d-flex align-items-center"
        style={position === "left" ? { right: 0, left: "unset" } : {}}
      >
        <span onClick={(e) => handleReaction(e, "like")}>{reactionsIcons.like}</span>
        <span onClick={(e) => handleReaction(e, "love")}>{reactionsIcons.love}</span>
        <span onClick={(e) => handleReaction(e, "laugh")}>{reactionsIcons.laugh}</span>
        <span onClick={(e) => handleReaction(e, "information")}>{reactionsIcons.information}</span>
        <span onClick={(e) => handleReaction(e, "support")}>{reactionsIcons.support}</span>
      </div>
    </div>
  );
}
