import React from "react";
import Modal from "react-bootstrap/Modal";
import { ReactComponent as CrossIcon } from "../../assets/icons/closeIcon.svg";
import MyForm from "../formComponent";
import { useFormik } from "formik";
import { updatePasswordSchema } from "../../utils/validationSchemas";
import { Form } from "react-bootstrap";
import { handleApiRequest } from "../../services/handleApiRequest";
import { updatePassword } from "../../redux/auth/thunk";
import { successMsg } from "../../utils/toastMessage";

const defaultPasswordValues = {
  oldPassword: "",
  newPassowrd: "",
  confirmPassword: "",
};
export const changePasswordFields = [
  { value: "oldPassword", type: "password" },
  { value: "password", type: "password" },
  { value: "confirmPassword", type: "password" },
];

export default function UpdatePassword({ userAction, setUserAction }) {
  const handleClose = () => {
    setUserAction({});
  };

  const handleUpdatePassword = async () => {
    const response = await handleApiRequest(updatePassword, values);

    if (response.status) {
      setUserAction(null);
      successMsg("Password updated successfully!!");
    }
  };

  const { values, errors, handleSubmit, handleChange, setFieldValue, isSubmitting } = useFormik({
    validateOnChange: false,
    validateOnBlur: false,
    initialValues: defaultPasswordValues,
    validationSchema: updatePasswordSchema,
    onSubmit: handleUpdatePassword,
  });

  return (
    <Modal
      show={userAction.action}
      onHide={handleClose}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="uppdatePasswordModal"
    >
      <Modal.Body>
        <div className="position-relative py-4 px-2">
          <p
            className="pointer position-absolute"
            style={{ top: "-62px", right: "-10px" }}
            onClick={handleClose}
          >
            <CrossIcon />
          </p>

          <div className="modal_uploadcv_confimation update_password text-start">
            <h4 className="mb-5 text-center">Update password!</h4>

            <Form className="" onSubmit={handleSubmit}>
              <div className="my-4">
                <MyForm
                  formFields={changePasswordFields}
                  values={values}
                  setFieldValue={setFieldValue}
                  errors={errors}
                  handleChange={handleChange}
                />
              </div>

              {isSubmitting ? (
                <button className="secondaryBtn text-dark rounded py-1 mb-3 mt-4 w-100" disabled>
                  Updating your password
                  <i className="growLoader spinner-grow ms-2" role="status" />
                </button>
              ) : (
                <button
                  type="submit"
                  className="secondaryBtn border-secondary py-1 rounded w-100 mb-3 mt-4"
                >
                  Update Password
                </button>
              )}
            </Form>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}
