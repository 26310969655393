import moment from "moment";

export const isArray = (array) => {
  if (array && Array.isArray(array)) return array;
  return [];
};

export const numberOnly = (value) => {
  const number = value.replace(/[^0-9]/g, "");
  return number;
};

export const characterOnly = (value) => {
  const number = value.replace(/[^a-zA-Z]/g, "");
  return number;
};

export const preventNegative = (e) => {
  if (!e) return;
  if (e.code === "Minus" || e.code === "NumpadSubtract") {
    e.preventDefault();
  }
};

export const format_phone_number = (value) => {
  const formattedSSN = value
    .replace(/[^0-9]/g, "")
    .replace(/(\d{3})(\d{1,3})(\d{0,4})/, (_, p1, p2, p3) => {
      let formattedNumber = `${p1}-${p2}`;
      if (p3) {
        formattedNumber += `-${p3}`;
      }
      return formattedNumber;
    })
    .slice(0, 12);
  return formattedSSN;
};

export function format_date(date) {
  if (!date) return;

  const myDate = moment(date).format("DD MMM, YYYY");
  if (date) {
    return myDate;
  }
}

export function format_time(time) {
  if (!time || !moment(time).isValid()) return "";
  const myTime = moment(time).format("hh:mm A");
  return myTime;
}

export function format_datetime(date) {
  const myDate = moment(date).format("DD MMM, YYYY hh:mm A");
  if (date) {
    return myDate;
  } else {
    return "--";
  }
}

export function parseKey(key) {
  if (!key) return;
  let parsedKey = key
    .toString()
    .split("_")
    .map((text) => `${text[0].toUpperCase()}${text.slice(1).toLowerCase()}`)
    .join(" ");
  return parsedKey;
}

export function parseCamelKey(key) {
  if (!key || typeof key !== "string") return "";
  let parsedKey = key
    .replace(/([A-Z])/g, "_$1")
    .toLowerCase()
    .split("_")
    .map((text) => `${text[0].toUpperCase()}${text.slice(1).toLowerCase()}`)
    .join(" ");
  return parsedKey;
}

export const handleTransformValues = async (values) => {
  if (!values || typeof values !== "object") return;
  let request = { ...values };

  return new Promise(async (resolve, reject) => {
    for (let key of Object.keys(values || {})) {
      if (
        typeof values[key] === "object" &&
        values[key]?.hasOwnProperty("label") &&
        values[key]?.hasOwnProperty("value")
      ) {
        request[key] = values[key].value;
      } else if (typeof values[key] === "object" && values[key]?._id && key !== "avatar") {
        request[key] = values[key]._id;
      } else {
        request[key] = values[key];
      }
    }

    resolve(request);
  });
};
