import { createSlice } from "@reduxjs/toolkit";
import { getOtherUserProfile } from "./thunk";

const initialState = {
  userProfile: {},
};

const slice = createSlice({
  name: "otherUserProfile",
  initialState: { ...initialState },
  reducers: {},
  extraReducers: (builder) => {
    // builder
    //   .addCase(getUserProfile.pending, (state, action) => {})
    //   .addCase(getUserProfile.fulfilled, (state, action) => {
    //     state.userProfile = action.payload?.data || {};
    //   })
    //   .addCase(getUserProfile.rejected, (state, action) => {});
  },
});

export default slice.reducer;
