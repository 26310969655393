import React, { useEffect, useState } from "react";
import { Form, Modal } from "react-bootstrap";
import { useFormik } from "formik";
import { ReactComponent as CrossIcon } from "../../assets/icons/closeIcon.svg";
import MyForm from "../formComponent";
import { updateProfileSchema } from "../../utils/validationSchemas";
import { handleApiRequest } from "../../services/handleApiRequest";
import { getUserProfile, updateUserProfile } from "../../redux/auth/thunk";
import { successMsg } from "../../utils/toastMessage";
import { genderOptions } from "../../utils/constants";
import { handleTransformValues } from "../../utils/formatersAndParsers";
import { useSelector } from "react-redux";
import { getUserImage } from "../../utils/helpers";
import { MdEdit } from "react-icons/md";
import { handleFile } from "../../services/handleFile";
import Image from "../common/image";

const defaultValues = {
  name: "",
  mobile: "",
  gender: "",
  avatar: "",
  address: "",
  birth_date: "",
};

export const profileUpdateFields = [
  {
    value: "name",
    type: "text",
    placeholder: "Enter full name",
    label: "Full Name",
  },
  { value: "mobile", type: "phone" },
  { value: "birth_date", type: "date" },
  { value: "gender", type: "select", options: genderOptions },
  { value: "address", type: "text" },
  { value: "bio", type: "text" },
];

export default function UpdateProfile({ userAction, setUserAction }) {
  const { userProfile = {} } = useSelector((state) => state.auth || {});

  const [uploadingImage, setUploadingImage] = useState(false);

  const handleClose = () => {
    setUserAction({});
  };

  const handleUserProfile = async () => {
    await handleApiRequest(getUserProfile);
  };

  const handleUploadFile = async (e) => {
    const file = e.target?.files[0];
    setUploadingImage(true);

    const response = await handleFile(file);
    const { url, filename } = response[0];

    setFieldValue("avatar", { url, name: filename });
    setUploadingImage(false);
  };

  const handleUpdateProfile = async () => {
    try {
      const request = await handleTransformValues(values);
      const response = await handleApiRequest(updateUserProfile, request);
      handleClose();

      if (response.status) {
        successMsg("Profile updated successfully!!");
        handleUserProfile();
      }
    } catch (error) {
      setUserAction(null);
    }
  };

  const { values, errors, handleSubmit, handleChange, setFieldValue, setValues, isSubmitting } =
    useFormik({
      validateOnChange: false,
      validateOnBlur: false,
      initialValues: defaultValues,
      validationSchema: updateProfileSchema,
      onSubmit: handleUpdateProfile,
    });

  useEffect(() => {
    if (userProfile._id) {
      setValues({ ...defaultValues, ...userProfile });
    }
  }, [userProfile]);

  return (
    <Modal
      show={userAction.action}
      onHide={handleClose}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="uppdatePasswordModal"
    >
      <Modal.Body>
        <div className="position-relative py-4 px-2">
          <p
            className="pointer position-absolute"
            style={{ top: "-62px", right: "-10px" }}
            onClick={handleClose}
          >
            <CrossIcon />
          </p>

          <div className="modal_uploadcv_confimation update_password text-start">
            <div className="w-fit mx-auto text-center position-relative">
              <Image
                src={uploadingImage ? "" : values.avatar?.url || getUserImage(userProfile)}
                alt={userProfile.name}
                className="img-large border border-primary"
              />

              <input
                type="file"
                id="updateAvatar"
                className="d-none"
                onChange={handleUploadFile}
                disabled={uploadingImage}
              />

              <label
                htmlFor="updateAvatar"
                className="updateAvatarIcon pointer position-absolute d-flex align-items-center justify-content-center rounded-circle"
              >
                <MdEdit />
              </label>
            </div>

            <Form className="" onSubmit={handleSubmit}>
              <div className="my-4">
                <MyForm
                  formFields={profileUpdateFields}
                  values={values}
                  setFieldValue={setFieldValue}
                  errors={errors}
                  handleChange={handleChange}
                />
              </div>

              {isSubmitting ? (
                <button className="secondaryBtn text-dark rounded py-1 mb-3 mt-4 w-100" disabled>
                  Updating your details
                  <i className="growLoader spinner-grow ms-2" role="status" />
                </button>
              ) : (
                <button
                  type="submit"
                  className="secondaryBtn border-secondary py-1 rounded w-100 mb-3 mt-4"
                  disabled={uploadingImage}
                >
                  Update Profile
                </button>
              )}
            </Form>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}
