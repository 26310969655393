export const signinRoute = "/auth/sign-in";
export const signupRoute = "/auth/sign-up";
export const resetPasswordRoute = "/auth/reset-password";

export const homeRoute = "/";
export const storiesRoute = "/stories";
export const bookmarkedStoriesRoute = "/stories/bookmarked";
export const privateDirectoryRoute = "/stories/private";
export const myQueriesRoute = "/queries/list";
export const storyDetailsRoute = "/story";
export const communitiesRoute = "/support/community";
export const chat = "/chat";
export const addStoryRoute = "/story/add";
export const editStoryRoute = "/story/update";
export const myAccountRoute = "/my-account";
export const profileRoute = "/profile";
export const contactUsRoute = "/contact-us";
export const otherUserProfile = "/user";
export const cmsPage = "/cms/page";
