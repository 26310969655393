import React from "react";
import { useNavigate } from "react-router-dom";
import useAuth from "../../hooks/useAuth";
import { addStoryRoute, signinRoute } from "../../routes/routesPath";

export default function YourStoryIsWaitingToHappen() {
  const { isAuthenticated } = useAuth();
  const navigate = useNavigate();

  return (
    <div className="d-block d-md-flex align-self-center align-items-center justify-content-center rounded py-4 px-2 px-sm-5 mx-auto text-center">
      <h4 className="text-white m-0 ms-md-5 my-3">Your story is waiting to happen!</h4>
      <div className="mx-md-5 text-center">
        <button
          className="primaryBtn rounded-pill px-4 py-2"
          onClick={() => navigate(isAuthenticated ? addStoryRoute : signinRoute)}
        >
          Get Started
        </button>
      </div>
    </div>
  );
}
