import React from "react";
import { Link } from "react-router-dom";
import { FaFacebookF, FaInstagram, FaYoutube } from "react-icons/fa6";
import { FaTelegramPlane } from "react-icons/fa";
import { socialHandles } from "../../utils/constants";

export function InstagramHandle() {
  return (
    <Link to={socialHandles.instagram} target="_blank" className="homeSocialIcon">
      <FaInstagram />
    </Link>
  );
}

export function FacebookHandle() {
  return (
    <Link to={socialHandles.facebook} target="_blank" className="homeSocialIcon">
      <FaFacebookF />
    </Link>
  );
}

export function YoutubeHandle() {
  return (
    <Link to={socialHandles.youtube} target="_blank" className="homeSocialIcon">
      <FaYoutube />
    </Link>
  );
}

export function TelegramHandle() {
  return (
    <Link to={socialHandles.telegram} target="_blank" className="homeSocialIcon">
      <FaTelegramPlane />
    </Link>
  );
}
